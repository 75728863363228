
@import "@/assets/styles/variables.scss";
@import '@/assets/styles/components.scss';
@import "@/assets/styles/requestReset.scss";

.disclaimer {
  text-align: center;
  font-size: 0.8rem;
}

.error {
  color: red;
  text-align: center;
}

.submitMessage {
  color: black;
  text-align: center;
}

.forgot-link {
  text-align: right;
}

.button-container {
  width: 100%;
}

.submitted {
  background: linear-gradient(60deg, #00b3bb 0%, #34319e 100%) !important;
}
