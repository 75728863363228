
@import "@/assets/styles/variables.scss";

.login {

  &__header {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__logo {
    margin-bottom: 1rem;
  }

  &__form {
    position: relative;
    background: white;
    box-shadow: 0px 10px 20px rgba(100, 100, 100, 0.5);
    border-radius: 16px;
    height: 570px;
    width: 460px;
    padding: 1.5rem 2rem;
  }

  &__title {
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 500;
    letter-spacing: 1px;
    text-align: center;
    margin-bottom: 1rem;
  }

  &__strap {
    font-size: 0.9rem;
    line-height: 1.5rem;
    font-weight: 500;
    letter-spacing: 1px;
    text-align: center;
  }

  &__resend {
    font-size: 0.9rem;
    text-align: center;
  }

  &__button {
    background: $brand-gradient;
    height: 2.5rem;
    width: 100%;
    margin-bottom: 14px;
    border-radius: 18px;
    border: none;
    font-size: 1rem;
    color: white;
    font-weight: 600;
    cursor: pointer;

    &:disabled {
      background: $na-color;
      border: 1px solid grey;
      color: grey;
      cursor: not-allowed;
    }
  }

  &__feedback {
    min-height: 30px;
    text-align: center;
    font-size: 0.9rem;
    line-height: 1.8rem;
    font-weight: 500;
    letter-spacing: 1px;
    margin-bottom: 1rem;
  } 

  &__message {
    color: $brand-primary;
  }

  &__error {
    color: red;
  }

  &__otp {
    margin-bottom: 1.5rem;
  }

  .form {
    display: flex;
    flex: 1;
    flex-flow: column;
    justify-content: space-between;
    width: 100%;
    height: 100%;
  }

  hr {
    border: 0px;
    border-bottom: 1px solid $fill-grey;
    width: 100%;
  }

  .disclaimer {
    text-align: center;
    font-size: 0.8rem;
  }

  .error {
    color: red;
    text-align: center;
  }

  .forgot-link {
    text-align: right;
    margin-bottom: 15px;
  }
}

.otp-input {
    width: 50px;
    height: 50px;
    padding: 3px;
    margin: 0 4px;
    font-size: 20px;
    border-radius: 24px;
    border: 1px solid $black-30pc;
    text-align: center;
    font-weight: 600;
  }
  .otp-input::-webkit-inner-spin-button,
  .otp-input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.error {
  .otp-input {
    border: 1px solid red;

    &:disabled {
      background: $na-color;
      border: 1px solid grey;
      color: grey;
      cursor: not-allowed;
    }
  }
}
