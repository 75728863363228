
@import "@/assets/styles/variables.scss";
@import "@/assets/styles/components.scss";

.field {
  display: flex;
  flex-flow: column;
  margin-bottom: 1rem;
  label {
    font-weight: 600;
    font-size: 0.9rem;
    padding-bottom: 0.5rem;
  }
  span.icon {
    bottom: 0rem;
  }
}
