@font-face {
    font-family: AllRoundGothicBold;
    font-weight: 500;
    font-style: normal;
    src: url('~@/assets/fonts/AllRoundGothicBold.woff');
}

@font-face {
    font-family: AllRoundGothicBook;
    font-weight: 400;
    font-style: normal;
    src: url('~@/assets/fonts/AllRoundGothicBook.woff');
}

@font-face {
    font-family: AllRoundGothicDemi;
    font-weight: 600;
    font-style: normal;
    src: url('~@/assets/fonts/AllRoundGothicDemi.woff');
}

:root {
  font-size: 16px;
}

#app {
  position: relative;
  height: 100vh;
  width: 100vw;
  background: #e5e5e5;
  font-family: AllRoundGothicBook, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body,html {
    // font-family: AllRoundGothicBook;
    color: #3c3c3b;
    // font-size: 1rem;
}

body, p, a {
  margin: 0;
}

* {
  box-sizing: border-box;
}

/* Loader Animation */
@-webkit-keyframes loading {
  to { -webkit-transform: rotate(360deg); }
}
@-moz-keyframes loading {
	to { -moz-transform: rotate(360deg); }
}
@-ms-keyframes loading {
	to { -ms-transform: rotate(360deg); }
}
@keyframes loading {
	to { transform: rotate(360deg); }
}

/* Loader (*/
.loading {
	width: 40px;
	height: 40px;
	border-radius: 50%;
	background: transparent;
  display: inline-block;
	
	border-top: 4px solid #fff;
	border-right: 4px solid #fff;
	border-bottom: 4px solid #777;
	border-left: 4px solid #777;
	
	-webkit-animation: loading 1.2s infinite linear;
	-moz-animation: loading 1.2s infinite linear;
	-ms-animation: loading 1.2s infinite linear;
	animation: loading 1.2s infinite linear;
}