
@import "@/assets/styles/variables.scss";

div.navbar {
  align-items: center;
  display: flex;
  flex-flow: row;
  justify-content: space-evenly;
  margin-bottom: $margin1x;
  margin-top: $margin1x;
  padding: 0 1rem;

  ul.navitems {
    display: inline-flex;
    height: 100%;
    justify-content: space-evenly;
    margin: 0;
    padding: 0;
    width: 100%;

    li {
      border-radius: 99px;
      cursor: pointer;
      font-weight: 600;
      list-style-type: none;
      margin-top: 0.5rem;
      padding: 0.5rem 1rem;
      text-align: center;

      &:hover,
      &:focus,
      &.active,
      &.selected {
        @extend .active-option;
      }
    }

    a {
      text-decoration: unset;

      li {
        color: lightgrey;
        margin: $margin-half;
        padding: $padding-half;
      }

      &.is-active > li {
        @extend .active-option;
      }
    }
  }
}

.active-option {
  // background: linear-gradient(90deg, #ed6762 0%, #ea5393 100%);
  background: $brand-gradient;
  color: white !important;
}
