
@import "@/assets/styles/variables.scss";

.dashboard {
  position: relative;
  display: flex;
  flex-flow: column;
  width: 100%;
  height: 100%;

  .banner {
    position: relative;
    width: 100%;
    height: 3.5rem;
    top: 0;
    // background: linear-gradient(90deg, #ec685d 0.56%, #ea5296 97.23%);
    background: $brand-gradient;
    display: grid;
    align-items: center;
    grid-auto-flow: column;
    grid-template-columns: repeat(2, max-content) 1fr;
    &__img {
      position: relative;
      padding-left: $padding1x;
      height: auto;
      width: 54px;
    }
    &__text {
      position: relative;
      padding-left: $padding1x;
      font-weight: 600;
      font-size: $font1x;
      color: white;
    }
    .logout {
      cursor: pointer;
      color: white;
      justify-self: flex-end;
      padding-right: 1rem;
    }
  }

  .main {
    position: relative;
    flex: 1;
    margin: $margin1x;
    background-color: white;
    border: $content-border;
    border-radius: $content-border-radius;
    display: flex;
    max-height: calc(100vh - 7.5rem);
    aside {
      flex-basis: $sidebar-width;
    }

    section {
      flex-basis: auto;
    }

    .maincontent {
      display: flex;
      flex-direction: column;
      width: calc(100% - #{$sidebar-width});
      .content {
        height: 100%;
        width: 100%;
        padding: $padding1x;
        padding-bottom: 0;
        margin-bottom: 1rem;
        overflow: scroll;
      }
    }
  }

  footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: static;
    bottom: 0;
    height: 2rem;
    width: 100vw;
    background: white;
    color: $footer-text;
    p {
      padding-right: $padding1x;
      font-size: $font1x;
    }
  }
}

.current-review {
  margin-left: 25%;
}

.audit-text {
  font-weight: 100 !important;
}
