
@import '@/assets/styles/variables.scss';
@import '@/assets/styles/components.scss';

.login {
  &__form {
    position: relative;
    background: white;
    box-shadow: 0px 10px 20px rgba(100, 100, 100, 0.5);
    border-radius: 16px;
    height: 570px;
    width: 460px;
    padding: 1.5rem 2rem;
  }

  &__title {
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 500;
    letter-spacing: 1px;
  }

  &__button {
    background: $brand-gradient;
    height: 2.5rem;
    width: 100%;
    margin-bottom: 14px;
    border-radius: 18px;
    border: none;
    font-size: 1rem;
    color: white;
    font-weight: 600;
    cursor: pointer;
  }

  .form {
    display: flex;
    flex: 1;
    flex-flow: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
  }

  hr {
    border: 0px;
    border-bottom: 1px solid $fill-grey;
    width: 100%;
  }

  .disclaimer {
    text-align: center;
    font-size: 0.8rem;
  }

  .error {
    color: red;
    text-align: center;
  }

  .forgot-link {
    text-align: right;
    margin-bottom: 15px;
  }
}
