
@import "@/assets/styles/variables.scss";

.sidebar {
  position: absolute;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: $sidebar-width;
  padding: $padding1x;
  overflow-y: auto;
  border-right: $content-border;

  ul {
    padding: 0;
    margin: 0;
    height: 100%;

    li {
      list-style-type: none;
      text-align: center;
      border-radius: 99px;
      font-weight: 600;
      cursor: pointer;

      &:hover,
      &:focus,
      &.active,
      &.selected {
        @extend .active-option;
      }
    }

    a {
      text-decoration: unset;

      li {
        padding: $padding-half;
        margin: $margin-half;
        color: lightgrey;
      }

      &.is-active > li {
        @extend .active-option;
      }
    }

    .router-link-active li {
      @extend .active-option;
    }
  }

  .active-option {
    // background: linear-gradient(90deg, #ed6762 0%, #ea5393 100%);
    background: $brand-gradient;
    color: white !important;
  }
}
