
@import "@/assets/styles/variables.scss";

.grade {
  position: relative;
  height: 100%;
  width: 100%;
  border-radius: 99px;
  border: unset;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.15);
  // transition: box-shadow 150ms ease-in-out;
  font-size: 0.9rem;
  // line-height: 1.25rem;
  // color: white;
  font-weight: 600;
  font-family: AllRoundGothicBook;
  display: flex;
  align-items: center;
  justify-content: center;
  // transition: all 150ms ease-in-out;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
}

.grade.selected {
  opacity: 1;
}
.grade.not-selected {
  opacity: 0.3;
}
