.requestReset{
  &__form {
    position: relative;
    background: white;
    // border: 1px solid rgba(0, 0, 0, 0.2);
    box-shadow: 0px 10px 20px rgba(100, 100, 100, 0.5);
    // box-sizing: border-box;
    border-radius: 16px;
    height: 600px;
    width: 460px;
    padding: 1.5rem 2rem;
  }
  &__title {
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 500;
    letter-spacing: 1px;
  }
  // &__input {
  //   background-color: white;
  //   border: 1px solid rgba(0, 0, 0, 0.5);
  //   height: 36px;
  //   width: 100%;
  //   margin-bottom: 14px;
  //   border-radius: 18px;
  //   font-size: 1rem;
  // }
  &__button {
    background: $brand-gradient;
    height: 2.5rem;
    width: 100%;
    margin-bottom: 14px;
    border-radius: 18px;
    border: none;
    font-size: 1rem;
    color: white;
    font-weight: 600;
  }

  .form {
    display: flex;
    flex: 1;
    flex-flow: column;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    height: 100%;
  }

  hr {
    border: 1px solid $fill-grey;
    width: 100%;
  }
}
